.site-header {
  padding: 10px 0;
}

#homeIntro,
#blogPosts {
  background-image: url("./Assets/Image/Header/header.webp");
  background-position: center center;
  background-size: contain;
}

.react-photo-album--photo {
  object-fit: cover;
  object-position: center;
}

.blog-image img {
  height: 600px !important;
  width: 100% !important;
  object-fit: contain;
}

.blog-image.category img {
  height: 300px !important;
  width: 300px !important;
  object-fit: contain;
}

.news-desc p {
  margin-bottom: 1rem;
}

.logo-header {
  height: auto;
  width: 150px;
}

@media only screen and (max-width: 600px) {
  .logo-header {
    height: auto;
    width: 300px;
  }
}